import React, { useState } from "react"
import { Cancel, CancelRounded, Stars, TrendingUp } from "@mui/icons-material"
import { Chrono } from "react-chrono"
import { Backdrop, Button } from "@mui/material"
import { Timeline, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineDot, timelineItemClasses } from "@mui/lab"

const QuestionComponent = ({ questionStatement, handleQuestionSelect, alignment }) => {
  const [showEntireQues, setShowEntireQues] = useState(false)

  const problemStatement = questionStatement?.ProblemStatement || ""

  return (
    <>
      {questionStatement && (
        <>
          <button
            className="text-sm text-gray-200 text-left my-[0.5]"
            onClick={() => handleQuestionSelect(questionStatement.questionId, questionStatement.ToolSelected, questionStatement.Level[`${questionStatement.ToolSelected}`])}>
            {!showEntireQues ? `${problemStatement.substring(0, 100)}...` : problemStatement}
          </button>
          <div className={`flex-row-container ${alignment}`}>
            <button
              onClick={() => {
                setShowEntireQues(!showEntireQues)
              }}
              className="text-xs text-gray-400 cursor-pointer">
              {showEntireQues ? "Read Less" : "Read More"}
            </button>
          </div>
        </>
      )}
    </>
  )
}

// Filter component
const QuestionList = ({ questionHistory = [], handleQuestionsHistory, isOpen, onClose }) => {
  const handleQuestionSelect = (questionId, toolName, level) => {
    handleQuestionsHistory(questionId, toolName, level)
  }
  //console.log({ questionHistory })

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsFilterVisible(false) // Close dropdown if clicking outside
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside)
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside)
  //   }
  // }, [dropdownRef])
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      className="backdrop-blur">
      <div
        className={`sidebar ${isOpen ? "open" : ""}`}
        style={{
          backgroundColor: "#232926",
          width: "35vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: "65vw",
          zIndex: 1,
          color: "#fefefe",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",

          overflowY: "auto",
          borderRadius: "8px",
          opacity: "95%",
          backdropFilter: "blur(30px)",
          WebkitBackdropFilter: "blur(30px)",
          zIndex: "1500",
        }}>
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#171721",
            zIndex: 1500,
          }}
          className="p-3">
          <div className="flex-row-bw-container">
            <h3 className="font-medium">
              <span className="text-gray-200">Your Journey</span>
            </h3>
            <button
              className="close-btn p-0 text-gray-200"
              onClick={onClose}>
              <Cancel />
            </button>
          </div>
          <div className="flex-row-bw-container style-list status-list my-1 text-base">
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#22c55e4a" }} />
              <span className="!text-base text-[#22c55e8a]">Correct</span>
            </p>
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#EAB3084a" }} />
              <span className="!text-base text-[#EAB3088a]">Attempted</span>
            </p>
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#ccc9c94a" }} />
              <span className="!text-base text-[#ccc9c98a]">Visited</span>
            </p>
          </div>
        </div>

        {/*<div
          className="arena-question-list bg-[#0F1015]"
          style={{ padding: "20px" }}>
          <Chrono
            mode="VERTICAL"
            focusActiveItemOnLoad={true}
            theme={{
              cardBgColor: "#16171B",
              cardForeColor: "#fefefe",
              primary: "#fefefe1a",
              secondary: "#fefefe",
            }}
            timelinePointDimension={25}
            style={{
              minWidth: "400px",
            }}
            disableToolbar
            cardHeight={""}
            cardWidth={"400"}
            className="chrono-container"
            classNames={{
              card: "chrono-card",
              cardMedia: (questionItem) => (questionItem.isAnswerCorrect ? "is-correct" : "is-incorrect"),
            }}
            useReadMore={true} // Enable read more functionality
          >
            {questionHistory &&
              questionHistory.map((questionItem, index) => {
                const cardClass = "" //questqionItem.isAnswerAttempted ? (questionItem.isAnswerCorrect ? "chrono-card-correct" : "chrono-card-incorrect") : "chrono-card-attempted"
                if (questionItem.Level)
                  return (
                    <div
                      key={index}
                      className={`chrono-icons ${cardClass}`}>
                      <div className={`chrono-card-content card-left min-w-full`}>
                        <li className="text-sm text-gray-400 bg-[#242529] px-2 py-1 rounded">Solved in : {questionItem.ToolSelected}</li>

                        <li className="text-sm text-gray-400 bg-[#242529] px-2 py-1 rounded">Level : {questionItem.Level ? (questionItem.Level[questionItem.ToolSelected] ? questionItem.Level[questionItem.ToolSelected] : questionItem.Level.Python) : ""}</li>

                        {questionItem.isAnswerCorrect && <li className="text-sm text-gray-400">Reward points : {questionItem.rewardPoints}</li>}
                      </div>
                      <QuestionComponent
                        questionStatement={questionItem}
                        handleQuestionSelect={handleQuestionSelect}
                        alignment={"card-right min-w-full"}
                      />
                    </div>
                  )
              })}
          </Chrono>
        </div> */}
        <div
          className="arena-question-list bg-[#0F1015]"
          style={{ padding: "20px" }}>
          <Timeline
            className="!p-0 overflow-auto"
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}>
            {questionHistory &&
              questionHistory.map((questionItem, index) => {
                const cardClass = "" //questqionItem.isAnswerAttempted ? (questionItem.isAnswerCorrect ? "chrono-card-correct" : "chrono-card-incorrect") : "chrono-card-attempted"
                if (questionItem.Level)
                  return (
                    <TimelineItem key={questionItem.questionId}>
                      <TimelineSeparator>
                        <TimelineDot sx={{ bgcolor: questionItem.isAnswerCorrect ? "#22c55e4a" : questionItem.isAnswerAttempted ? "#EAB3084a" : "#CCC9C94a" }} />
                        <TimelineConnector sx={{ bgcolor: questionItem.isAnswerCorrect ? "#22c55e4a" : questionItem.isAnswerAttempted ? "#EAB3084a" : "#CCC9C94a" }} />
                      </TimelineSeparator>
                      <TimelineContent className={`!text-gray-300  hover:!bg-opacity-70 rounded-md transition-all cursor-pointer !my-3 !mx-2 ${questionItem.isAnswerCorrect ? "bg-[#22c55e4a]" : questionItem.isAnswerAttempted ? "bg-[#EAB3084a]" : "bg-[#CCC9C94a]"}`}>
                        <div
                          key={index}
                          className={`chrono-icons ${cardClass}`}>
                          <div className={`chrono-card-content card-left min-w-full`}>
                            <p className="p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40"> {questionItem.ToolSelected}</p>

                            <p className="p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40">
                              {" "}
                              <TrendingUp className="!text-base" /> {questionItem.Level ? (questionItem.Level[questionItem.ToolSelected] ? questionItem.Level[questionItem.ToolSelected] : questionItem.Level.Python) : ""}
                            </p>

                            {questionItem.isAnswerCorrect && (
                              <p className="p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40">
                                {" "}
                                <Stars className="!text-base" /> {questionItem.rewardPoints}
                              </p>
                            )}
                          </div>
                          <QuestionComponent
                            questionStatement={questionItem}
                            handleQuestionSelect={handleQuestionSelect}
                            alignment={"card-right min-w-full"}
                          />
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  )
              })}
          </Timeline>
        </div>
      </div>
    </Backdrop>
  )
}

export default React.memo(QuestionList)
