import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, defs, linearGradient, stop } from 'recharts';
import Dropdown from '../ui/Dropdown';
import { MenuItem } from '@mui/material';
import { CustomToolTip, CustomXAxisTick } from '../../utils/graphCustomeComponen';


const NewBarChart = ({ data }) => {

    const Gradient = () => (
        <defs>
            <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
        </defs>
    );


    const TOOLS = ["All", "Python", "SQL", "Excel"];
    const [selectedTool, setSelectedTool] = useState("All");
    const [aggregatedData, setAggregatedData] = useState([])

    const aggregateData = (data, subject) => {
        const aggregated = {};

        data.forEach(entry => {
            const levels = entry[subject];
            Object.keys(levels).forEach(level => {
                if (!aggregated[level]) {
                    aggregated[level] = 0;
                }
                aggregated[level] += levels[level];
            });
        });

        return Object.keys(aggregated).map(level => ({
            level: "Level " + level,

            total: aggregated[level]
        }));
    };

    const aggregateAllSubjects = (data) => {
        const levelCounts = {};

        data.forEach(entry => {
            for (const subject of Object.keys(entry)) {
                if (subject === 'name') continue;

                const questions = entry[subject];

                for (const [level, count] of Object.entries(questions)) {
                    if (levelCounts[level]) {
                        levelCounts[level] += parseInt(count, 10);
                    } else {
                        levelCounts[level] = parseInt(count, 10);
                    }
                }
            }
        });

        const result = Object.entries(levelCounts).map(([level, total]) => ({
            level: "Level " + level,
            total
        }));

        return result;
    }

    useEffect(() => {
        if (selectedTool !== "All") {
            setAggregatedData(aggregateData(data, selectedTool));
        } else {
            setAggregatedData(aggregateAllSubjects(data));
        }
    }, [selectedTool, data])
    return (
        <>
            <div className='mr-auto px-4 my-2'>
                <Dropdown
                    title={selectedTool}
                    children=
                    {TOOLS.map((tool, index) => (
                        <MenuItem
                            onClick={() => { setSelectedTool(tool) }}
                            className='!text-gray-200 !font-light !text-xs'
                            value={tool} key={tool}>{tool}</MenuItem>
                    ))}
                />
            </div>
            <div className='flex flex-col h-full justify-center w-full'>
                {
                    aggregatedData.length === 0 ?
                        <h1 className="text-sm text-center text-gray-400 justify-self-start">No Data to Display <br></br>Solve Questions to see your Progress!</h1> :
                        <ResponsiveContainer className="!p-0" width="100%" height={250}>
                            <BarChart width={600} height={300} data={aggregatedData}>
                                <defs>
                                    <linearGradient
                                        id="colorUv"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="100%"
                                        spreadMethod="reflect"
                                    >
                                        <stop offset="0" stopColor="#9f1239" />
                                        <stop offset="1" stopColor="#fb7185" />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="level"
                                    axisLine={{ stroke: "white" }}
                                    tickLine={{ stroke: "white" }}
                                    tick={<CustomXAxisTick />}
                                    interval={0}
                                    angle={-20}
                                    textAnchor="middle"
                                    height={50}
                                    fontSize={12}
                                    dy={10}
                                />
                                <YAxis
                                    axisLine={{ stroke: "white" }}
                                    tickLine={{ stroke: "white" }}
                                    tick={{ fill: "white" }}
                                    fontSize={12}
                                />
                                <Tooltip content={<CustomToolTip />} cursor={{fill: '#374151', opacity:".5"}}/>
                                <Bar dataKey="total"
                                    fill="url(#colorUv)"
                                    radius={[8, 8, 0, 0]}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                }
            </div>
        </>
    )
}


export default NewBarChart;
