import React, { useState, useEffect } from "react"
import "../styles.css"
import Loader from "./loader"
import { Assessment } from "@mui/icons-material"
import { TablePagination } from "@mui/material"

const ResultComponent = ({ result, isAnswerCorrect, resultLoader, submittedKey }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10) // Default rows per page
  const [page, setPage] = useState(0) // Local page state for pagination

  // Reset page when the result changes
  useEffect(() => {
    setPage(0)
  }, [result])

  // Handle page change for TablePagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Handle rows per page change for TablePagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset to the first page when rows per page change
  }

  // Determine the type of result to render the appropriate view
  const renderResult = () => {
    if (resultLoader) {
      return (
        <div className="m-9">
          <Loader />
        </div>
      )
    }
    if (!result) {
      return <p>Click Run or Submit for processing your answer.</p>
    }
    if (result.error) {
      return <div className="error-message">Error: {result.error}</div>
    }

    if (typeof result === "string") {
      return <div className="string-response">{result}</div>
    }

    if (Array.isArray(result) && result.length > 0) {
      // Handling table (array of objects) responses
      const columns = Object.keys(result[0])
      const paginatedRows = result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Slice data for pagination

      return (
        <>
          {isAnswerCorrect && submittedKey === "submit" && <h6 className={isAnswerCorrect && submittedKey === "submit" ? "text-green-300" : "text-red-500"}>{result ? (isAnswerCorrect ? "Correct Answer" : "Incorrect Answer") : ""}</h6>}
          <div className="table-container">
            <table className="result-table table-auto overflow-scroll">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      className="border-b-2 bg-[#232627] text-left text-xs font-medium text-gray-200">
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedRows.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`border-b border-gray-200 ${rowIndex % 2 === 0 ? "bg-gray-100" : ""}`}>
                    {columns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className="text-sm text-gray-400">
                        {row[column]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* TablePagination Component */}
          <TablePagination
            sx={{
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
                color: "#fefefe",
              },
              "& .MuiTablePagination-select": {
                color: "#fefefe",
                backgroundColor: "#232627",
                padding: "4px",
                borderRadius: "5px",
              },
              "& .MuiSvgIcon-root": {
                color: "#fefefe",
              },
              "& .MuiTablePagination-actions button": {
                color: "#fefefe",
              },
            }}
            component="div"
            count={result.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )
    } else {
      // Fallback for any other types of results
      return <p>Unexpected result format.</p>
    }
  }

  return (
    <div className="result-component">
      <div className="result-heading-container px-3 py-1">
        <h6 className="text-sm text-gray-400">
          <span className="text-emerald-600">
            <Assessment className="" />
          </span>{" "}
          Test Result
        </h6>
      </div>
      <div className="p-3 text-sm text-gray-400">{renderResult()}</div>
    </div>
  )
}

export default ResultComponent
